import React, { useEffect, useState } from "react";
import { contentfulClient } from "../services/contentful/connect";

const Skills = () => {
  const [loading, setLoading] = useState(true);
  const [skill, setSkills] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { items: Skills } = await contentfulClient.getEntries({
          content_type: "skill",
        });
        
        setSkills(Skills);

      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {loading && <p>Cargando...</p>}
      {(!loading) && (skill != null) && (
        <>
          <div className='grid grid-cols-5 lg:grid-cols-8 gap-6'>

            {(skill?.map(({ sys: { id }, fields: { titleskill, logo: { fields: { file: { url } } } } }) => (
              <article className='' key={id}>
                <figure className='relative pb-[100%] overflow-hidden'>
                  <img className='absolute top-0 left-0 w-full h-full object-contain' src={url} alt={titleskill} />
                </figure>
              </article>
            )))}

          </div>
        </>
      )}
    </>
  );
};

export default Skills;
