import React from "react";
import { Link } from "react-router-dom";

const ComponentCard = ({ title, image, slug }) => {
  return (
    <>
      <div className="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 h-full">
        <Link reloadDocument to={slug}>
          <img
            className="rounded-t-lg"
            src={ image }
            alt={ title } />
        </Link>
        <div className="p-5">
          <Link reloadDocument to={slug}>
            <h5 className="mb-2 text-xl lg:text-2xl text-left font-black tracking-tight text-primary dark:text-white font-dm-sans">
              { title }
            </h5>
          </Link>          
        </div>
      </div>
    </>
  );
};

export default ComponentCard;
